<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title-text">我的消息</span>
        <button class="delete_all" @click="deleteAll">一键删除</button>
      </div>
      <div v-if="hasData">
        <div v-for="item1 in dateTitle" :key="item1" style="margin-bottom: 30px" class="content-height">
          <div style="margin-bottom: 15px" class="flex between" v-if="item1 !== getToday">{{ item1 }} <div class="line"></div>
          </div>
          <div style="margin-bottom: 15px" class="flex between" v-else>今天 <div class="line"></div>
          </div>
          <div v-for="item2 in messageList" :key="item2.date">
            <div v-if="item2.date === item1">
              <div v-for="item3 in item2.notice" :key="item3.id" :class="[item3.isRead === 0 ? 'no-read' : 'has-read', {active: activeId === item3.id}]" @mouseover="onCssChange(item3.id)" @mouseleave="leaveCssChange">
                <!-- 展示内容-->
                <div @click="goToDetail(item3)" style="cursor: pointer" class="click-area">
                  <!--  这里是可点击区域-->
                  <span class="show-title" :style="{color: item3.isRead === 0 ? '#0E050B' : '#908E8F'}">{{ item3.title }}</span>
                  <span class="show-time">{{ item3.createTime }}</span>
                  <span class="show-content" :style="{color: item3.isRead === 0 ? '#3B373A' : '#B9B8B9'}">{{ item3.content }}</span>
                </div>
                <!-- <span class="show-time">{{ item3.createTime }}</span> -->
                <span class="show-redpoint" v-if="item3.isRead === 0"></span>
                <span class="show-delete" @click="deleteMessage(item3.id)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="none-data" v-else>
        <img src="@/assets/pc/images/personal/none.png" alt="">
        <span style="font-size: 14px; color: #B0B0B0">暂无内容</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { request } from '../../../network';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      pageNum: 1,
      pageSize: 100,
      messageList: [],
      dateTitle: [],
      activeId: 0,
      hasData: true
    };
  },
  created () {
    this.getFindMyNotice();
  },
  mounted () { },
  computed: {
    getToday () {
      return dayjs().format('YYYY/MM/DD');
    }
  },
  methods: {
    async getFindMyNotice () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/findMyNotice',
          params: {
            memberId: getMemberId(),
            currentPage: this.pageNum,
            pageSize: this.pageSize
          }
        });
        if (res.code !== 200) return this.$message.error('数据获取失败');
        this.messageList = res.data;
        if (this.messageList.length === 0) this.hasData = false;
        this.dateTitle = this.messageList.map(item => item.date);
        this.getnoreadmessage();
      }
    },
    onCssChange (id) {
      this.activeId = id;
    },
    leaveCssChange () {
      this.activeId = -1;
    },
    async getnoreadmessage () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/intelligentPush/getnoreadmessage',
        params: {
          memberId: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('未读消息获取失败');
      // this.$store.commit('personal/setReadStatus','1')
      this.$store.commit('personal/setNotRead', res.data);
      this.$store.commit('personal/setReadNum', res.data);
      localStorage.setItem('notRead', res.data);
      return Promise.resolve();
    },
    async updateStatusById (id) {
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateStatusById',
        params: {
          id: id
        }
      });
      if (res.code !== 200) return this.$message.error('查看失败');
      this.getnoreadmessage();
    },
    async goToDetail (data) {
      this.updateStatusById(data.id);
      if (data.noticeType === 2) {
        this.$router.push('/push/followpolicy');
      } else if (data.noticeType === 4) {
        this.$router.push({
          path: '/push/messageDetails',
          query: {
            id: data.fieldId
          }
        });
        // this.$router.push({ path: '/push/adaptationproject' });
      } else if (data.noticeType === 3 || data.noticeType === 5 || data.noticeType === 7) {
        console.log(data);
        this.$router.push({
          path: 'info/messagedetail',
          query: {
            id: data.id,
            noticeType: data.noticeType,
            fieldId: data.fieldId,
            createTime: data.createTime
          }
        });
      } else if (data.noticeType === 1) {
        this.$router.push({ path: '/push/adaptationproject' });
      } else if (data.noticeType === 6) {
        this.$router.push({
          path: '/eventServices/messageDetails',
          query: {
            id: data.fieldId
          }
        });
      } else if (data.noticeType === 8) {
        this.$router.push({
          path: '/member', query: {
            flag: '2'
          }
        });
      }
    },
    async deleteMessage (id) {
      const { data: res } = await request({
        method: 'DELETE',
        url: '/pcp/PersonalCenter/deleteNoticeById',
        params: {
          id
        }
      });
      if (res.code !== 200) return this.$message.error('删除失败');
      this.getFindMyNotice();
      this.$message.success('删除成功');
    },
    async deleteAll () {
      const { data: res } = await request({
        method: 'DELETE',
        url: '/pcp/PersonalCenter/deleteAllNotice',
        params: {
          memberId: getMemberId()
        }
      });
      if (res.code !== 200) return this.$message.error('删除失败');
      this.getFindMyNotice();
      this.$message.success('删除成功');
    }
  }
};
</script>

<style lang="less" scoped>
.content-height {
  overflow: auto;
}
.content-height::-webkit-scrollbar {
  width: 6px;
  height: 400px;
}
.content-height::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ececec;
}
.title-text {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-height: 40px;
  line-height: 40px;
}
/deep/.el-card__body {
  padding: 25px 40px;
}
.has-read {
  position: relative;
  margin-bottom: 10px;
  // width: 820px;
  height: 80px;
  border: 1px solid #f7f8fa;
  background-color: #f7f8fa;
  border-radius: 5px;
}
.no-read {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px;
  // width: 820px;
  height: 80px;
  border: 1px solid #e6e8ee;
  background-color: #fff;
  border-radius: 5px;
}
.show-title {
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
.click-area {
  width: 100%;
  height: 100%;
}
.show-content {
  display: block;
  position: absolute;
  bottom: 14px;
  left: 20px;
  font-size: 14px;
  color: #0e050b;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.show-time {
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 14px;
  color: #b9b8b9;
}
.show-delete {
  position: absolute;
  bottom: 32px;
  right: 20px;
  font-size: 12px;
  color: #555;
  cursor: pointer;
}
.active {
  border: 1px solid #156ed0;
}
.show-redpoint {
  z-index: 9999;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #f5222d;
  border-radius: 50%;
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
.line {
  height: 1px;
  flex: 1;
  background-color: #bbbbbb;
  margin-left: 17px;
}
.clearfix{
  display: flex;
  justify-content: space-between;
}
.delete_all{
  height: 40px;
  width: 100px;
  background: #156ed0;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
